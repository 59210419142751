import { API } from '../../../common/api'
import { UpdateResellerDataToBeSent } from '../../../admin/modules/resaleAuthorizations/actions'
import { FreeTrials } from '../../modules/freeTrials/reducer'
import {
  MarketplaceSearchUrl,
  marketplaceServiceUrl,
} from '../../../common/utils/constants'
import { GetRevenueInsightsReport } from '../../modules/revenueInsights/actions'
import { CloudType } from '../../../common/modules/types'
import { isEmpty } from 'lodash'
import { GetRevenueInsightsReportV2 } from '../../modules/revenueInsightsV2/actions'
import { encodeBase64Url } from '../../../common/utils/helperFunctions'
export const fetchProductsList = (
  partnerId: string,
  include?: string,
  cloudMarketplace?: CloudType,
  getFromCloud?: boolean
) => {
  return API.get(`${marketplaceServiceUrl}/partners/${partnerId}/products`, {
    params: {
      include: include || 'free_trial',
      ...(cloudMarketplace
        ? { cloud_marketplace: cloudMarketplace.toLocaleLowerCase() }
        : {}),
      ...(getFromCloud !== undefined ? { get_from_cloud: getFromCloud } : {}),
    },
  })
}
export const postContractsData = (
  data: Record<string, unknown>,
  partnerId: string
) => {
  return API.post(
    `${marketplaceServiceUrl}/partners/${partnerId}/meterings`,
    data
  )
}

export const getContractsData = (partnerId: string, productId: string) => {
  return API.get(
    `${marketplaceServiceUrl}/partners/${partnerId}/products/${productId}/contracts`,
    {
      params: {
        page_size: 500,
      },
    }
  )
}

export const getSearchedData = ({
  partnerId,
  productId,
  input,
  selectedInput,
  pageSize,
  pageNumber,
  sectionName,
  isRevenueSearch,
}: {
  partnerId: string
  productId?: string
  input?: string
  selectedInput?: string
  pageSize?: number
  pageNumber?: number
  sectionName?: string
  isRevenueSearch?: boolean
}) => {
  let encodedInput = ''
  let payloadQuery = `i=${sectionName}&q=${input}&field=${selectedInput}&page_size=${pageSize}&page_number=${pageNumber}`
  if (isEmpty(productId)) {
    encodedInput = encodeBase64Url(payloadQuery)
    return API.get(`${MarketplaceSearchUrl}/partners/${partnerId}/search`, {
      params: {
        p: encodedInput,
      },
    })
  } else {
    payloadQuery += `&${
      isRevenueSearch ? 'labra_product_id' : 'aws_product_id'
    }=${productId}`
    encodedInput = encodeBase64Url(payloadQuery)
    return API.get(`${MarketplaceSearchUrl}/partners/${partnerId}/search`, {
      params: {
        p: encodedInput,
      },
    })
  }
}

export const getPrivateOffersData = ({
  partnerId,
  pageSize,
  pageNumber,
  productId,
  crmOpportunityId,
  expiringInDays,
  offerStatus,
}: {
  partnerId: string
  productId?: string
  secureKey?: string
  pageSize?: number
  pageNumber?: number
  crmOpportunityId?: string
  expiringInDays?: string
  offerStatus?: string[]
}) => {
  return API.get(
    `${marketplaceServiceUrl}/partners/${partnerId}/private_offers`,
    {
      params: {
        page_size: pageSize,
        page_number: pageNumber,
        aws_product_id: productId,
        crm_opportunity_id: crmOpportunityId,
        include: 'free_trial',
        expiring_in_days: expiringInDays,
        offer_status: offerStatus?.join(','),
      },
    }
  )
}

export const patchPrivateOffersData = (
  partnerId: string,
  privateOfferId: string,
  data: Record<string, unknown>,
  saveAsDraft?: boolean
) => {
  return API.patch(
    `${marketplaceServiceUrl}/partners/${partnerId}/private_offers/${privateOfferId}`,
    data,
    {
      params: {
        draft: saveAsDraft,
      },
    }
  )
}

export const patchProductListingData = (
  partnerId: string,
  productId: string,
  data: Record<string, unknown>
) => {
  return API.patch(
    `${marketplaceServiceUrl}/partners/${partnerId}/products/${productId}`,
    data
  )
}

export const deleteProductListing = (partnerId: string, productId: string) => {
  return API.delete(
    `${marketplaceServiceUrl}/partners/${partnerId}/products/${productId}`
  )
}

export const publishProductListing = (
  partnerId: string,
  productId: string,
  data: Record<string, unknown>
) => {
  return API.patch(
    `${marketplaceServiceUrl}/partners/${partnerId}/products/${productId}`,
    data
  )
}

export const unpublishProductListing = (
  partnerId: string,
  productId: string,
  data: Record<string, unknown>
) => {
  return API.patch(
    `${marketplaceServiceUrl}/partners/${partnerId}/products/${productId}`,
    data
  )
}

export const getSinglePrivateOffer = (
  partnerId: string,
  privateOfferId: string,
  crmOpportunityId?: string
) => {
  return API.get(
    `${marketplaceServiceUrl}/partners/${partnerId}/private_offers/${privateOfferId}`,
    {
      params: {
        crm_opportunity_id: crmOpportunityId,
      },
    }
  )
}

export const getSingleSubscriptionDetail = (
  partnerId: string,
  subscriptionId: string
) => {
  return API.get(
    `${marketplaceServiceUrl}/partners/${partnerId}/subscriptions/${subscriptionId}`
  )
}

export const postToSignedUrl = (presignedUrl: string, formData: FormData) => {
  return API.post(`${presignedUrl}`, formData, {
    headers: {
      Authorization: '',
    },
  })
}

export const postFreeTrial = (
  partnerId: string,
  productFreeTrial: FreeTrials
) => {
  return API.post(
    `${marketplaceServiceUrl}/partners/${partnerId}/free_trials`,
    productFreeTrial
  )
}
export const patchFreeTrial = (
  partnerId: string,
  data: Record<string, string>,
  freeTrialId: string
) => {
  return API.patch(
    `${marketplaceServiceUrl}/partners/${partnerId}/free_trials/${freeTrialId}`,
    data
  )
}

export const fetchResellerAgreementData = (
  partnerId: string,
  pageSize: number,
  pageNumber: number,
  sortBy: string,
  sortOrder: string,
  productId?: string,
  crmOpportunityId?: string
) => {
  return API.get(
    `${marketplaceServiceUrl}/partners/${partnerId}/aws_reseller_agreements`,
    {
      params: {
        product_id: productId,
        page_size: pageSize,
        page_number: pageNumber,
        sort_by: sortBy,
        sort_order: sortOrder,
        crm_opportunity_id: crmOpportunityId,
      },
    }
  )
}
export const patchResellerData = (
  partnerId: string,
  resellerAgreementId: string,
  dataToBeSent: UpdateResellerDataToBeSent,
  saveAsDraft = false
) => {
  return API.patch(
    `${marketplaceServiceUrl}/partners/${partnerId}/aws_reseller_agreements/${resellerAgreementId}`,
    dataToBeSent,
    {
      params: {
        draft: saveAsDraft,
      },
    }
  )
}

export const getSingleResaleAuthorization = (
  partnerId: string,
  resaleAuthorizationId: string,
  crmOpportunityId?: string
) => {
  return API.get(
    `${marketplaceServiceUrl}/partners/${partnerId}/aws_reseller_agreements/${resaleAuthorizationId}`,
    {
      params: {
        crm_opportunity_id: crmOpportunityId,
      },
    }
  )
}
export const getPreSignedUrl = (
  partnerId: string,
  path: string,
  bucketName?: string
) => {
  return API.get(`${marketplaceServiceUrl}/partners/${partnerId}/assets`, {
    params: {
      fields: 'aws_presigned_url',
      assets: path,
      operation_method: 'POST',
      expires_in: 3600,
      bucket_name: bucketName ? bucketName : '',
    },
  })
}

export const postAssets = (
  partnerId: string,
  data: Record<string, unknown>
) => {
  return API.post(`${marketplaceServiceUrl}/partners/${partnerId}/assets`, data)
}
export const postPrivateOffer = ({
  partnerId,
  data,
  saveAsDraft,
}: {
  partnerId: string
  data: Record<string, unknown>
  saveAsDraft?: boolean
}) => {
  return API.post(
    `${marketplaceServiceUrl}/partners/${partnerId}/private_offers`,
    data,
    {
      params: {
        draft: saveAsDraft || false,
      },
    }
  )
}
export const getReports = ({
  partnerId,
  reportType,
  cloudMarketplace,
  finalStartDate,
  finalEndDate,
  downloadReport,
  pageSize,
  pageNumber,
  sortBy,
  sortOrder,
  productId,
}: GetRevenueInsightsReport) => {
  return API.get(`${marketplaceServiceUrl}/partners/${partnerId}/reports`, {
    params: {
      report_type: reportType,
      cloud_marketplace: cloudMarketplace,
      labra_product_id: productId,
      from_date: finalStartDate,
      to_date: finalEndDate,
      download_report: downloadReport,
      page_size: pageSize,
      page_number: pageNumber,
      sort_by: sortBy,
      sort_order: sortOrder,
    },
    responseType: downloadReport ? 'blob' : 'json',
  })
}
export const getReportsV2 = ({
  partnerId,
  finalStartDate,
  finalEndDate,
  pageSize,
  productId,
  dateRangeType,
  paymentStatus,
  offerType,
  disbursementDate,
  paymentDueDate,
  paymentDelayedSince,
  lastEvaluatedKeys,
  lastEvaluatedValues,
  sortBy,
  downloadReport,
  sortOrder,
  shouldSendGetDateParam,
}: GetRevenueInsightsReportV2) => {
  return API.get(
    `${marketplaceServiceUrl}/partners/${partnerId}/revenue_reports`,
    {
      params: {
        labra_product_id: productId,
        from_date: finalStartDate,
        to_date: finalEndDate,
        page_size: 100,
        last_evaluated_keys: lastEvaluatedKeys,
        last_evaluated_values: lastEvaluatedValues,
        date_range_filter: dateRangeType,
        disbursement_status: paymentStatus,
        offer_visibility: offerType,
        disbursement_date: disbursementDate,
        payment_due_date: paymentDueDate,
        payment_delayed_since: paymentDelayedSince,
        get_insert_dates: shouldSendGetDateParam,
        download_report: downloadReport,
      },
      responseType: downloadReport ? 'blob' : 'json',
    }
  )
}
export const postResaleAuthorization = ({
  partnerId,
  data,
  saveAsDraft = false,
}: {
  partnerId: string
  data: Record<string, unknown>
  saveAsDraft?: boolean
}) => {
  return API.post(
    `${marketplaceServiceUrl}/partners/${partnerId}/aws_reseller_agreements`,
    data,
    {
      params: {
        draft: saveAsDraft,
      },
    }
  )
}

export const fetchResellers = (partnerId: string) => {
  return API.get(
    `${marketplaceServiceUrl}/partners/${partnerId}/aws_resellers`,
    {
      params: {
        page_size: Number.MAX_SAFE_INTEGER,
      },
    }
  )
}

export const fetchSellerData = ({
  partnerId,
  metadata,
}: {
  partnerId: string
  metadata: Record<string, unknown>
}) => {
  return API.get(`${marketplaceServiceUrl}/partners/${partnerId}`, {
    params: {
      ...metadata,
    },
  })
}

export const patchSellersData = (
  partnerId: string,
  sellersData: Record<string, unknown>,
  cloudMarketplace: string,
  operations: string
) => {
  return API.patch(
    `${marketplaceServiceUrl}/partners/${partnerId}`,
    sellersData,
    {
      params: {
        cloud_marketplace: cloudMarketplace,
        operations: operations,
      },
    }
  )
}

export const postMigrationSyncData = (
  partnerId: string,
  syncData: Record<string, unknown>
) => {
  return API.post(
    `${marketplaceServiceUrl}/partners/${partnerId}/syncs`,
    syncData
  )
}

export const postSellerCreation = (data: Record<string, unknown>) => {
  return API.post(`${marketplaceServiceUrl}/partners`, data)
}

export const getFlyOutOnboardingFormData = (partnerId: string) => {
  return API.get(`${marketplaceServiceUrl}/partners/${partnerId}/onboarding`)
}

export const putFlyOutOnboardingFormData = (
  partnerId: string,
  data: Record<string, unknown>
) => {
  return API.put(
    `${marketplaceServiceUrl}/partners/${partnerId}/onboarding`,
    data
  )
}

export const deleteAsset = (
  partnerId: string,
  data: Record<string, string>
) => {
  return API.delete(`${marketplaceServiceUrl}/partners/${partnerId}/assets`, {
    data,
  })
}

export const fetchSubscriptionList = ({
  partnerId,
  pageSize,
  pageNumber,
  crmOpportunityId,
}: {
  partnerId: string
  pageSize?: number
  pageNumber?: number
  crmOpportunityId?: string
}) => {
  return API.get(
    `${marketplaceServiceUrl}/partners/${partnerId}/subscriptions`,
    {
      params: {
        page_size: pageSize,
        page_number: pageNumber,
        crm_opportunity_id: crmOpportunityId,
        include: 'entitlements',
        sort_by: 'contract_expiration_date',
        sort_order: 'asc',
        exclude_status: 'unsubscribe_success,subscription_fail',
      },
    }
  )
}

export const putFlyOutProductFormData = ({
  partnerId,
  data,
  productId,
}: {
  partnerId: string
  data: Record<string, unknown>
  productId?: string
}) => {
  return API.put(
    `${marketplaceServiceUrl}/partners/${partnerId}/aws/products`,
    data,
    {
      params: {
        ...(productId ? { product_id: productId } : {}),
      },
    }
  )
}

export const getFlyOutProductFormData = ({
  partnerId,
  productId,
  editPage,
}: {
  partnerId: string
  productId?: string
  editPage?: boolean
}) => {
  return API.get(
    `${marketplaceServiceUrl}/partners/${partnerId}/aws/products/${productId}`,
    {
      params: {
        ...(editPage ? { edit_page: true } : {}),
      },
    }
  )
}

export const patchFlyOutProductFormData = ({
  partnerId,
  productId,
  data,
}: {
  partnerId: string
  productId?: string
  data: Record<string, unknown>
}) => {
  return API.patch(
    `${marketplaceServiceUrl}/partners/${partnerId}/aws/products/${productId}`,
    data
  )
}

export const getOtherProductDetails = ({
  partnerId,
  listingLink,
}: {
  partnerId: string
  listingLink: string
}) => {
  return API.put(
    `${marketplaceServiceUrl}/partners/${partnerId}/external_listing`,
    {
      listing_link: listingLink,
    }
  )
}

export const putOnboardingFormData = ({
  partnerId,
  data,
}: {
  partnerId: string
  data: Record<string, unknown>
}) => {
  return API.put(
    `${marketplaceServiceUrl}/partners/${partnerId}/aws/onboarding`,
    data
  )
}

export const getMilestonesData = ({ partnerId }: { partnerId: string }) => {
  return API.get(`${marketplaceServiceUrl}/partners/${partnerId}`, {
    params: {
      seller_milestones: true,
    },
  })
}

export const patchMilestonesData = (
  partnerId: string,
  data: Record<string, unknown>
) => {
  return API.patch(`${marketplaceServiceUrl}/partners/${partnerId}`, data, {
    params: {
      operations: 'update_seller_milestones',
      cloud_marketplace: 'aws',
    },
  })
}
